import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import { IMG_DUMMY } from '../../../../utils/imgURL';



const ImageWithFallback = ({ src, alt, style }) => {
  const [ imgSrc, setImgSrc ] = useState(src?.split('?')[0] || IMG_DUMMY);

  const handleError = () => {
    setImgSrc(IMG_DUMMY);
  };

  return (
    <Image
      src={imgSrc}
      alt={alt}
      style={{
        objectFit: 'contain',
        margin: '5px',
        ...style,
      }}
      onError={handleError}
    />
  );
};

ImageWithFallback.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default ImageWithFallback;
