import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getAIOptions, getGroupOptions } from '../helpers/helpers';
import MySelect from '../../../../widgets/MySelect';
import { useToggle } from '../../../../hooks/useToggle';
import { entityTypes } from '../../config/config';
import AiTags from './AiTags';
import CreateTagConfirmationModal from './CreateTagConfirmationModal';
import { useTagFormContext } from './TagFormContext';



const GroupList = ({ groups, onCreateTag, aiTags, type = 'local' }) => {
  const [ isActive, toggleModal ] = useToggle();
  const [ tagData, setTagData ] = useState({});
  const { formValues, control, setValue, errors } = useTagFormContext();

  const {
    lang,
  } = useSelector(({
    language,
  }) => ({
    lang: language.lang,
  }), shallowEqual);

  const onCreate = (fieldName, group, data) => {
    if (typeof onCreateTag === 'function') {
      onCreateTag(data, (tag) => {
        const newTag = {
          label: tag.name,
          value: tag.id,
        };

        if (group.isMultiple) {
          const isAdded = (formValues[fieldName] || []).some((item) => Number(item.value) === Number(tag.id));

          if (!isAdded) {
            setValue(fieldName, [ ...(formValues[fieldName] || []), newTag ], { shouldValidate: true, shouldDirty: true });
          }
        } else {
          setValue(fieldName, newTag, { shouldValidate: true, shouldDirty: true });
        }
      });
    }
  };

  const addAiTag = (fieldName, tagName, group) => {
    if (!fieldName || !tagName || !group) return;

    const currentValue = formValues[fieldName] || [];
    const groupTag = group?.tags.find((tg) => tg.name === tagName);

    if (!groupTag) return;

    const aiTag = {
      label: groupTag?.name,
      value: groupTag?.id,
    };

    if (group.isMultiple) {
      const isTagPresent = currentValue.some((item) => item.value === tagName);

      if (!isTagPresent) {
        setValue(fieldName, [ ...currentValue, aiTag ], { shouldValidate: true, shouldDirty: true });
      }
    } else {
      if (currentValue.value !== tagName) {
        setValue(fieldName, aiTag, { shouldValidate: true, shouldDirty: true });
      }
    }
  };

  const onCreateFastTag = (data) => {
    let parents = [];

    if (data.parents) {
      parents = data.parents;
    } else {
      parents.push(tagData.group.id);
    }

    onCreate(tagData?.fieldName, tagData.group, {
      name: tagData.data,
      type: entityTypes.tag,
      parents,
      children: [],
      options: [],
    });
    toggleModal();
  };

  return (
    <>
      <CreateTagConfirmationModal
        show={isActive}
        onConfirm={onCreateFastTag}
        onCancel={toggleModal}
        context={{
          tagData,
          groups,
        }}
      />
      {groups.map((group) => {
        const options = getGroupOptions(group.tags, formValues);
        const fieldName = `${type}_${group.fieldName}`;
        const aiOptions = getAIOptions(formValues, aiTags, group) || [];

        return (
          <div key={group.name} className='d-flex flex-column'>
            <Form.Group>
              <Form.Label>
                <strong>
                  {group.hint}
                </strong>
                {group.hint ? ` (${group.name})` : group.name}
                {group.isRequired && <span style={{ color: 'red' }}>*</span>}
              </Form.Label>
              <Controller
                name={fieldName}
                control={control}
                render={({ field }) => {
                  return (
                    <MySelect
                      {...field}
                      as={group.creatable && CreatableSelect}
                      onCreateOption={(data) => {
                        setTagData({
                          data,
                          group,
                          fieldName,
                        });
                        toggleModal();
                      }}
                      isClearable
                      isMulti={group.isMultiple}
                      options={options}
                      isInvalid={!!errors[fieldName]}
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">
                {lang['TAG_SYSTEM.ERRORS.TAG_PICTURE_TAG_SELECTOR_ERROR']}
              </Form.Control.Feedback>
              {group.useAiSuggestions && (
                <AiTags
                  tags={aiOptions}
                  group={group}
                  fieldName={fieldName}
                  addAiTag={addAiTag}
                />
              )}
            </Form.Group>
          </div>
        );
      })}
    </>
  );
};

GroupList.defaultProps = {
  aiTags: {},
};

GroupList.propTypes = {
  groups: PropTypes.array.isRequired,
  aiTags: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  onCreateTag: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default GroupList;
