import React from 'react';
import objectPath from 'object-path';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import checkRole from '../../../app/utils/checkRole';
import MenuSubmenu from './MenuSubmenu';
import MenuItemText from './MenuItemText';
import {parseFromPars} from "../../../app/utils/parseUrlParams";
import urlPageTagSystem from '../../../app/urls/urlPageTagSystem';
import config from '../../../config/app';
import urlPageLogout from '../../../app/urls/urlPageLogout';



class MenuItem extends React.Component {
    asideLeftLIRef = React.createRef();

    isDropdown = document.body.classList.contains('kt-aside-menu--dropdown');

    submenuToggle =
        this.props.item.toggle === 'click'
          ? 'click'
          : objectPath.get(this.props.item, 'submenu.type') === 'tabs'
            ? 'tabs'
            : 'hover';

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter = () => {
      if (!this.isDropdown) {
        return;
      }

      if (this.props.item.submenu) {
        this.asideLeftLIRef.current.classList.add('kt-menu__item--hover');
        this.asideLeftLIRef.current.setAttribute(
          'data-ktmenu-submenu-toggle',
          this.submenuToggle,
        );
      }
    };

    /**
     * Mouse Leave event
     */
    mouseLeave = () => {
      if (!this.isDropdown) {
        return;
      }

      if (this.props.item.submenu && this.submenuToggle === 'hover') {
        this.asideLeftLIRef.current.classList.remove('kt-menu__item--hover');
        this.asideLeftLIRef.current.removeAttribute('data-ktmenu-submenu-toggle');
      }
    };

    showTagSystem = (user, item) => {
      if (user?.id && (item.page === urlPageTagSystem() && config.hideTagSystemById !== user.id)) {
        document.querySelectorAll(`[href="${urlPageTagSystem()}"]`).forEach(element => {
          element.closest('li').style.setProperty('display', 'block', 'important');
        });
      }
    }

   hideMenuItemsForTagManagers = (user) => {
    if (user?.id) {
      const tagManagers = [
        'aliaksei.shydlouski1',
        'hanna.shydlouskaya1',
        'dzmitry.shydlouski1',
        'yuliya.hutsaliuk1',
        'kristina.zaruba1',
        'hanna.viarchenava1',
        'maryna.ramanenkava1',
        'darya.krysevich1',
      ];
      if (tagManagers.includes(user.username)) {
        document.querySelectorAll('a').forEach(element => {
          const href = element.getAttribute('href');
          if (href && href !== urlPageLogout() && !href.includes(urlPageTagSystem())) {
            const listItem = element.closest('li');
            if (listItem) {
              listItem.style.setProperty('display', 'none', 'important');
            }
          }
        });
      }
    }
  }

    isMenuItemIsActive = (item) => {
      if (!item.page) {
        return false;
      }

      if (item.page.startsWith('/projects/list/coloring?')) {
        this.props.currentUrl.split('?')
        const pars = parseFromPars(this.props.currentUrl.replace('/projects/list/coloring', ''));
        const pagePars = parseFromPars(item.page);
        const isProjectTypeMatch = item.page.includes(`project_type=${pars.project_type}&`) || item.page.endsWith(`project_type=${pars.project_type}`);

        if (pagePars.status) {
          return isProjectTypeMatch && item.page.includes(`status=${pars.status}`);
        }

        return isProjectTypeMatch;
      }

      return this.props.currentUrl.startsWith(item.page);
    };

    render () {
      const { item, currentUrl, parentItem, layoutConfig, roles, user } = this.props;
      const isActive = this.isMenuItemIsActive(item);

      const submenu = item.submenu && item.submenu.filter((item) => checkRole(roles, item.roles, item.antiRoles || []));
      this.showTagSystem(user, item);
      this.hideMenuItemsForTagManagers(user);

      return (
        <li
          ref={this.asideLeftLIRef}
          {...(item.data ? item.data : {})}
          aria-haspopup="true"
          data-placement="right"
          data-ktmenu-submenu-mode={item.mode}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          className={clsx(
            'kt-menu__item',
            item.page === urlPageTagSystem() ? 'd-none' : '',
            {
              'kt-menu__item--submenu': item.submenu,
              'kt-menu__item--open kt-menu__item--here': isActive,
              'kt-menu__item--icon-only': item['icon-only'],
            },
            item['custom-class'],
          )}
          data-ktmenu-dropdown-toggle-class={item['dropdown-toggle-class']}
        >
          <Link to={item.page} className="kt-menu__link">
            <MenuItemText item={item} parentItem={parentItem} />
            {submenu && submenu.length > 0 && <i className="kt-menu__ver-arrow la la-angle-right" />}
          </Link>

          {submenu && submenu.length > 0 && (
            <div className="kt-menu__submenu">
              <span className="kt-menu__arrow" />

              {item['custom-class'] === 'kt-menu__item--submenu-fullheight' && (
                <div className="kt-menu__wrapper">
                  <MenuSubmenu
                    item={item}
                    parentItem={item}
                    currentUrl={currentUrl}
                    submenu={submenu}
                  />
                </div>
              )}

              {item['custom-class'] !== 'kt-menu__item--submenu-fullheight' && (
                <MenuSubmenu
                  item={item}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                  submenu={submenu}
                />
              )}
            </div>
          )}
        </li>
      );
    }
}


const mapStoreToProps = (store) => {
  return {
    roles: store.user.roles,
    user: store.user.user,
  };
};


export default connect(mapStoreToProps)(MenuItem);
