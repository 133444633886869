import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { isBYPersonalData, isRU } from '../../../utils/checker';
import { DatePickerForwardRef as MyDatePicker } from '../../../widgets/DatePicker';
import { toAbsoluteUrl } from '../../../utils/gettersUrl';
import { isFabros, isXFLOW } from '../../../utils/isFabros';
import MySelect from '../../../widgets/MySelect';
import getCountriesOptionsList from '../../../utils/getCountriesOptionsList';



const DocumentInformationTab = ({ step, control, errors, formValues }) => {
  const { lang, countries } = useSelector(({ language, countries }) => ({
    lang: language.lang,
    countries: countries.countries,
  }), shallowEqual);
  const currentStep = 2;

  return (
    <div
      className="kt-wizard-v4__content"
      data-ktwizard-type="step-content"
      data-ktwizard-state={step === currentStep && 'current'}
    >
      <div className="kt-heading kt-heading--md">
        {lang['REGISTRATION.ENTER_PASSPORT_DATA']}
      </div>
      <div className="kt-form__section kt-form__section--first">
        <div className="kt-wizard-v4__form">
          <div className="row">
            {!isRU(formValues) && isFabros && (
              <Form.Group data-tour="personal_number" className="col-12 col-xl-6">
                <Form.Label>
                  {lang['REGISTRATION.PASSPORT_ID']}
                </Form.Label>
                <Controller
                  name="personal_number"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.personal_number}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.PASSPORT_ID_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.PASSPORT_ID_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {isRU(formValues) && (
              <Form.Group data-tour="passport-number" className="col-12 col-xl-6">
                <Form.Label>
                  {lang['REGISTRATION.PASSPORT_TYPE']}
                </Form.Label>
                <Controller
                  name="passport_type"
                  control={control}
                  render={({ field }) => (
                    <MySelect
                      {...field}
                      name="passport_type"
                      isInvalid={!!errors.passport_type}
                      value={formValues?.passport_type || { value: 'ordinary', label: lang['REGISTRATION.ORDINARY_PASSPORT'] }}
                      options={[
                        { value: 'ordinary', label: lang['REGISTRATION.ORDINARY_PASSPORT'] },
                        { value: 'foreign', label: lang['REGISTRATION.FOREIGN_PASSPORT'] },
                      ]}
                    />)}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.PASSPORT_TYPE_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.PASSPORT_TYPE_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group data-tour="passport-number" className="col-12 col-xl-6">
              <Form.Label>
                {lang['REGISTRATION.PASSPORT_NUMBER']}
              </Form.Label>
              <Controller
                name="passport_number"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    autoComplete="off"
                    type="text"
                    isInvalid={!!errors.passport_number}
                  />
                )}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.PASSPORT_NUMBER_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.PASSPORT_NUMBER_ERROR']}{' '}{isRU(formValues) && (formValues.passport_type?.value === 'foreign' ? `(${lang['REGISTRATION.PASSPORT_FOREIGN_LENGTH']})` : `(${lang['REGISTRATION.PASSPORT_ORDINARY_LENGTH']})`)}
              </Form.Control.Feedback>
            </Form.Group>

            {isFabros && <Form.Group className="col-12" data-tour="issued-by">
              <Form.Label>
                {lang['REGISTRATION.PASSPORT_ISSUED']}
              </Form.Label>
              <Controller
                name="organization"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    autoComplete="off"
                    type="text"
                    isInvalid={!!errors.organization}
                  />
                )}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.PASSPORT_ISSUED_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.PASSPORT_ISSUED_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>}

            {isFabros && <Form.Group className="col-xl-6">
              <Form.Label>
                {lang['REGISTRATION.PASSPORT_DATE_ISSUE']}
              </Form.Label>
              <Controller
                name="date_of_issue"
                control={control}
                render={({ field }) => (
                  <MyDatePicker
                    {...field}
                    isInvalid={!!errors.date_of_issue}
                    maxDate={new Date()}
                  />
                )}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.PASSPORT_DATE_ISSUE_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.PASSPORT_DATE_ISSUE_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>}

            <Form.Group className="col-xl-6">
              <Form.Label>
                {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION']}
              </Form.Label>
              <Controller
                name="expiration_date"
                control={control}
                render={({ field }) => (
                  <MyDatePicker
                    {...field}
                    isInvalid={!!errors.expiration_date}
                    minDate={new Date()}
                  />
                )}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>

            {isXFLOW &&
            <Form.Group className="col-12">
              <Form.Label>
                {lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT']}
              </Form.Label>
              <Controller
                name="country_issuing_document"
                control={control}
                render={({ field }) => (
                  <MySelect
                    {...field}
                    isClearable
                    placeholder={lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT_PLEASE']}
                    className="kt-mb-15"
                    isInvalid={!!errors.country_issuing_document}
                    options={getCountriesOptionsList(countries)}
                  />
                )}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>}

            {isBYPersonalData(formValues) && isFabros && (
              <div className="col-12">
                <div className="kt-heading kt-heading--md">
                  {lang['REGISTRATION.ENTER_INSURANCE']}
                </div>
                <div className="row" data-tour="insurance-card">
                  <div className="col-xl-6 col-12">
                    <div>
                      <img
                        src={toAbsoluteUrl('/media/docs/soc_card.jpg')}
                        alt="insurance_card"
                        style={{
                          width: '400px',
                          borderRadius: '15px',
                          display: 'block',
                          margin: '2em auto',
                        }}
                      />
                    </div>
                  </div>
                  <Form.Group className="kt-mb-15 col-xl-6">
                    <Form.Label>
                      {lang['REGISTRATION.INSURANCE']}
                    </Form.Label>
                    <Controller
                      name="insurance_number"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          autoComplete="off"
                          type="text"
                          isInvalid={!!errors.insurance_number}
                        />
                      )}
                    />
                    <Form.Text className="text-muted">
                      {lang['REGISTRATION.INSURANCE_PLEASE']}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {lang['REGISTRATION.INSURANCE_ERROR']}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentInformationTab.propTypes = {
  step: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default DocumentInformationTab;
