import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import PropTypes from 'prop-types';
import countryActions from '../../store/countries/actions';
import userActions from '../../store/user/actions';
import { isFabros, isXFLOW } from '../../utils/isFabros';
import { isBY, isBYCitizenship, isBYResidence, isRU, isRUResidence, isTransitIban } from '../../utils/checker';
import { formatDate } from '../../utils/formats';
import LanguageSelector from '../../../_metronic/partials/layout/LanguageSelector';
import Modal from '../../widgets/Modal';
import Preload from '../../widgets/Preload';
import PersonalInformationTab from './components/PersonalInformationTab';
import DocumentInformationTab from './components/DocumentInformationTab';
import FinancialInformationTab from './components/FinancialInformationTab';
import ReviewTab from './components/ReviewTab';
import {
  fabrosDefaultFormValues,
  fabrosTabs,
  fabrosValidationSchema,
  xflowDefaultFormValues,
  xflowTabs,
  xflowValidationSchema,
} from './components/registrationFormData';
import RuXflowNotification from './components/ruXflowNotification';
import EnXflowNotification from './components/enXflowNotification';



const RegistrationUser = ({ code, showPrivacyPolicy, policy, term, showTerm }) => {
  const firstStep = 1;
  const lastStep = 4;
  const [ showModal, setShowModal ] = useState(false);
  const [ showXflowModal, setShowXflowModal ] = useState(false);
  const [ formData, setFormData ] = useState({});
  const dispatch = useDispatch();
  const { lang, loading, curLang } = useSelector(({ language, user }) => ({
    loading: user.loading,
    lang: language.lang,
    curLang: language.cur,
  }), shallowEqual);
  const [ step, setStep ] = useState(firstStep);
  const { control, watch, handleSubmit, formState: { errors }, trigger } = useForm({
    resolver: yupResolver(isFabros ? fabrosValidationSchema : xflowValidationSchema),
    mode: 'onChange',
    defaultValues: isFabros ? fabrosDefaultFormValues(lang) : xflowDefaultFormValues(lang),
  });
  const tabs = isFabros ? fabrosTabs(lang) : xflowTabs(lang);
  const watchAllFields = watch();

  useEffect(() => {
    dispatch(countryActions.fetchCountries());

    return () => {
      enableBodyScroll(window.root);
    };
  }, []);

  const onSubmit = async (data) => {
    // confirmModal();
    const userData = {
      ...data,
      birthday: formatDate(data.birthday, 'yyyy-MM-dd'),
      transit_iban: isTransitIban(data) ? data.transit_iban : null,
      expiration_date: formatDate(data.expiration_date, 'yyyy-MM-dd'),
      country: data.country?.value,
      policy_id: policy?.id,
      term_id: term?.id,
      code,
    };

    if (isFabros) {
      userData.citizenship = data.citizenship?.value;
      userData.date_of_issue = formatDate(data.date_of_issue, 'yyyy-MM-dd');
      userData.has_residence_confirmation = isBYCitizenship(data) ? Boolean(data.has_residence_confirmation?.value) : false;
      userData.has_been_on_territory = data.has_residence_confirmation?.value ? Boolean(data.has_been_on_territory?.value) : false;
      userData.residence = data.residence === null ? null : data.residence.value;
      userData.insurance_number = isBY(data) ? data.insurance_number : null;
      userData.personal_number = (!isRU(data) || isBYResidence(data)) && !isRUResidence(data) ? data.personal_number : null;
      userData.country_of_birth = data.country_of_birth?.value;
      userData.place_of_birth = data.place_of_birth;
      userData.inn = isRU(data) ? data.inn : null;
    }

    if (isXFLOW) {
      userData.country_issuing_document = data.country_issuing_document?.value;
      userData.bank_country = data.bank_country?.value;
      userData.tin_country = data.tin_country?.value;
    }

    setFormData(userData);
  };

  const confirmRegistration = () => {
    if (formData.hasOwnProperty('username')) {
      dispatch(userActions.registerUser(formData));
      setShowModal(false);
      setShowXflowModal(false);
    }
  };

  const prevStep = (event) => {
    event.preventDefault();
    setStep((prevStep) => {
      if (prevStep >= 2) {
        return prevStep - 1;
      }

      return prevStep;
    });
  };

  const nextStep = async (event) => {
    event.preventDefault();
    const isValid = await trigger(tabs[step - 1]?.fields);

    if (isValid) {
      setStep((prevStep) => {

        if (prevStep < lastStep) {
          return prevStep + 1;
        }

        return prevStep;
      });
    }
  };

  return (
    <>
      {loading ? <Preload />
        : <>
          <div className="kt-header__topbar d-flex justify-content-end">
            <LanguageSelector iconType="" />
          </div>
          <div
            className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
            style={{ padding: 25 }}
          >
            <div className="kt-wizard-v4">
              <div className="kt-wizard-v4__nav">
                <div className="kt-wizard-v4__nav-items">
                  {tabs.map((tab) => (
                    <div
                      key={tab.step}
                      className="kt-wizard-v4__nav-item"
                      data-ktwizard-state={step === tab.step && 'current'}
                    >
                      <div className="kt-wizard-v4__nav-body">
                        <div className="kt-wizard-v4__nav-number">{tab.step}</div>
                        <div className="kt-wizard-v4__nav-label">
                          <div className="kt-wizard-v4__nav-label-title">
                            {tab.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="kt-portlet" style={{ marginBottom: 0 }}>
                <div className="kt-portlet__body kt-portlet__body--fit">
                  <div className="kt-grid">
                    <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
                      <div
                        className="kt-form"
                        style={{ padding: 0, margin: '3.5rem 0' }}
                        data-tour="full"
                      >
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <PersonalInformationTab
                            step={step}
                            control={control}
                            errors={errors}
                            formValues={watchAllFields}
                          />
                          <DocumentInformationTab
                            step={step}
                            control={control}
                            errors={errors}
                            formValues={watchAllFields}
                          />
                          <FinancialInformationTab
                            formValues={watchAllFields}
                            step={step}
                            control={control}
                            errors={errors}
                          />
                          <ReviewTab
                            step={step}
                            formValues={watchAllFields}
                            control={control}
                            errors={errors}
                            showPrivacyPolicy={showPrivacyPolicy}
                            showTerm={showTerm}
                          />

                          <div className="kt-form__actions">
                            {step === firstStep ? (
                              <span />
                            ) : (
                              <button
                                className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                onClick={prevStep}
                              >
                                {lang['GLOBAL.BACK']}
                              </button>
                            )}
                            {step === lastStep ? (
                              <button
                                className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                data-tour="submit"
                                onClick={() => setShowModal(true)}
                                disabled={!watchAllFields.is_privacy_policy_applied || !watchAllFields.is_term_applied}
                              >
                                {lang['GLOBAL.SUBMIT']}
                              </button>
                            ) : (
                              <button
                                className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                data-tour="next-step"
                                onClick={nextStep}
                              >
                                {lang['GLOBAL.NEXT']}
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showModal}
            close={() => setShowModal(false)}
            footer={(
              <>
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  {lang['GLOBAL.CANCEL']}
                </button>
                <button
                  className="btn btn-success"
                  onClick={isXFLOW ? () => setShowXflowModal(true) : confirmRegistration}
                >
                  {lang['GLOBAL.SUBMIT']}
                </button>
              </>
            )}
          >
            <div>{lang['REGISTRATION.CONFIRMATION']}</div>
          </Modal>
          <Modal
            show={showXflowModal}
            close={() => setShowXflowModal(false)}
            footer={(
              <>
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowXflowModal(false)}
                >
                  {lang['GLOBAL.CANCEL']}
                </button>
                <button
                  className="btn btn-success"
                  onClick={confirmRegistration}
                >
                  {lang['REGISTRATION.CONFIRM']}
                </button>
              </>
            )}
          >
            <div>
              {curLang === 'ru' ? <RuXflowNotification /> : <EnXflowNotification />}
            </div>
          </Modal>
        </>
      }
    </>
  );
};

RegistrationUser.propTypes = {
  code: PropTypes.string.isRequired,
  showPrivacyPolicy: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  term: PropTypes.object.isRequired,
  showTerm: PropTypes.func.isRequired,
};

export default RegistrationUser;
