import React from 'react';
import { Form } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { isBYPersonalData, isRU, isTransitIban } from '../../../utils/checker';
import { formatDate } from '../../../utils/formats';
import { isFabros, isXFLOW } from '../../../utils/isFabros';



const ReviewTab = ({ step, formValues, control, errors, showPrivacyPolicy, showTerm }) => {
  const { lang, countries } = useSelector(({ language, countries }) => ({
    lang: language.lang,
    countries: countries.countries,
  }), shallowEqual);
  const currentStep = 4;

  const getCountyNameByCode = (code = null) => {
    return !isEmpty(countries) && code !== null
      ? countries.find((item) => item.code === code)?.name
      : '---';
  };

  return (
    <div
      className="kt-wizard-v4__content"
      data-ktwizard-type="step-content"
      data-ktwizard-state={step === currentStep && 'current'}
    >
      <div className="kt-heading kt-heading--md">
        {lang['REGISTRATION.REVIEW_AND_SUBMIT']}
      </div>
      <div className="kt-form__section kt-form__section--first">
        <div
          className="kt-wizard-v4__review"
          data-tour="checking"
        >
          <div className="kt-wizard-v4__review-item">
            <div className="kt-wizard-v4__review-title">
              {lang['REGISTRATION.PERSONAL_INFORMATION']}
            </div>
            <div className="kt-wizard-v4__review-content">
              {`${formValues.firstname} ${formValues.patronymic || ''} ${formValues.lastname}`}<br />
              {lang['REGISTRATION.USERNAME']}: {formValues.username}<br />
              {lang['REGISTRATION.BIRTHDAY']}: {formatDate(formValues.birthday)}<br />
              {lang['REGISTRATION.PHONE']}: {formValues.phone}<br />
              {lang['REGISTRATION.TELEGRAM']}: {formValues.telegram}<br />
              {isFabros && <>{lang['REGISTRATION.COUNTRY_OF_BIRTH']}: {getCountyNameByCode(formValues?.country_of_birth?.value)}<br /></>}
              {isFabros && <>{lang['REGISTRATION.PLACE_OF_BIRTH']}: {formValues?.place_of_birth}<br /></>}
              {isFabros && <>{lang['REGISTRATION.CITIZENSHIP']}: {getCountyNameByCode(formValues.citizenship?.value)}<br /></>}
              {isFabros && <>{lang['REGISTRATION.COUNTRY_RESIDENCE']}: {getCountyNameByCode(formValues.residence?.value) || '---'}<br /></>}
            </div>
          </div>
          <div className="kt-wizard-v4__review-item">
            <div className="kt-wizard-v4__review-title">
              {lang['REGISTRATION.DOCUMENT_INFORMATION']}
            </div>
            <div className="kt-wizard-v4__review-content">
              {isFabros && !isRU(formValues) && (
                <>
                  {lang['REGISTRATION.PASSPORT_ID']}: {formValues.personal_number || lang['GLOBAL.NOT_ENTERED']}<br />
                </>
              )}
              {lang['REGISTRATION.PASSPORT_NUMBER']}: {formValues.passport_number}<br />
              {isFabros && <>{lang['REGISTRATION.PASSPORT_ISSUED']}: {formValues.organization}<br /></>}
              {isFabros && <>{lang['REGISTRATION.PASSPORT_DATE_ISSUE']}: {formatDate(formValues.date_of_issue)}<br /></>}
              {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION']}: {formatDate(formValues.expiration_date)}<br />
              {isXFLOW && <>{lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT']}: {getCountyNameByCode(formValues.country_issuing_document?.value)}<br /></>}
              {isBYPersonalData(formValues) && isFabros && (<>{lang['REGISTRATION.INSURANCE']}: {formValues.insurance_number}</>)}
            </div>
          </div>
          <div className="kt-wizard-v4__review-item">
            <div className="kt-wizard-v4__review-title">
              {lang['REGISTRATION.FINANCE_INFORMATION']}
            </div>
            <div className="kt-wizard-v4__review-content">
              {lang['REGISTRATION.COUNTRY']}: {getCountyNameByCode(formValues.country?.value)}<br />
              {lang['REGISTRATION.CITY']}: {formValues.city}<br />
              {lang['REGISTRATION.ADDRESS']}: {formValues.address}<br />
              {isXFLOW && <>{lang['REGISTRATION.POST_CODE']}: {formValues.post_code}<br /></>}
              {isXFLOW && <>{lang['REGISTRATION.TIN_COUNTRY']}: {formValues.tin_country?.value}<br /></>}
              {isXFLOW && <>{lang['REGISTRATION.TIN_NUMBER']}: {formValues.tin_number}<br /></>}
              {lang['REGISTRATION.ACCOUNT_NUMBER']}: {formValues.iban}<br />
              {lang['REGISTRATION.SWIFT']}: {formValues.swift}<br />
              {isRU(formValues) && (<>{lang['REGISTRATION.BIC']}: {formValues.bic_number || '---'}<br /></>)}
              {isFabros && isRU(formValues) && (<>{lang['REGISTRATION.INN']}: {formValues.inn || '---'}</>)}
              {isXFLOW && <>{lang['REGISTRATION.BANK_NAME']}: {formValues.bank}<br /></>}
              {isXFLOW && <>{lang['REGISTRATION.BANK_COUNTRY']}: {getCountyNameByCode(formValues.bank_country?.value)}<br /></>}
              {isXFLOW && <>{lang['REGISTRATION.BANK_ACCOUNT_NAME']}: {formValues.bank_account_name}<br /></>}
              {isTransitIban(formValues) && (<>{lang['REGISTRATION.TRANSIT_IBAN']}: {formValues.transit_iban || '---'}</>)}
            </div>
          </div>
          {(isBYPersonalData(formValues) && formValues.is_allow_conversion) &&
            <div className="kt-wizard-v4__review-item">
              <div className="kt-wizard-v4__review-content">
                {<>{lang['REGISTRATION.ALLOW_CONVERSION']}: <b>Yes</b></>}
              </div>
            </div>
          }
          <div className="kt-wizard-v4__review-item">
            <Form.Group className="kt-mb-15">
              <Form.Check
                isInvalid
                type="checkbox"
              >
                <Controller
                  name="is_privacy_policy_applied"
                  control={control}
                  render={({ field }) => (
                    <Form.Check.Input
                      {...field}
                      type="checkbox"
                      isInvalid={!!errors.is_privacy_policy_applied}
                    />
                  )}
                />
                <Form.Check.Label onClick={showPrivacyPolicy}>
                  {lang['REGISTRATION.PRIVACY_POLICY_FIRST']} <a style={{ color: '#5867dd', fontWeight: 'bold' }}>{isFabros ? lang['REGISTRATION.PRIVACY_POLICY_CLICK'] : lang['REGISTRATION.XFLOW_PRIVACY_POLICY_CLICK']}</a>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">{lang['REGISTRATION.PRIVACY_POLICY_ERROR']}</Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
            <Form.Group className="kt-mb-0">
              <Form.Check
                isInvalid
                type="checkbox"
              >
                <Controller
                  name="is_term_applied"
                  control={control}
                  render={({ field }) => (
                    <Form.Check.Input
                      {...field}
                      type="checkbox"
                      isInvalid={!!errors.is_term_applied}
                    />
                  )}
                />
                <Form.Check.Label onClick={showTerm}>
                  {lang['REGISTRATION.TERM_FIRST']} <a style={{ color: '#5867dd', fontWeight: 'bold' }}>{lang['REGISTRATION.TERM_CLICK']}</a>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">{lang['REGISTRATION.TERM_ERROR']}</Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewTab.propTypes = {
  step: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  showPrivacyPolicy: PropTypes.func.isRequired,
  showTerm: PropTypes.func.isRequired,
};

export default ReviewTab;
