import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Preload from '../../widgets/Preload';
import Tabs from '../../widgets/Tabs';
import {
  getBonuses,
  setBonuses,
  buildBonuses,
  updateBonus,
  deleteBonus,
  createBonus,
  changeBonusStatus,
  getBatchTasksBonuses,
  sendBonusesApprovedConfirmation, getBonusesApprovedStagesRequest,
} from '../../requests/bonuses';
import urlPageBonusesSubmenu from '../../urls/urlPageBonusesSubmenu';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';
import { approveTester, updateTesterTrackingTime } from '../../requests/testers';
import { TASK_BONUS_STATUS } from '../../../const/tasks';
import taskTypes from '../tasks/taskTypes';
import { isFabros } from '../../utils/isFabros';
import { notification } from '../../requests/notifications';
import { lastAllowedDateToAcceptTesterBonus } from '../../../const/date';
import BonusesList from './components/BonusesList';
import BonusesStatsWidget from './components/BonusesStatsWidget';
import { bonusesApprovalStages } from './bonusesApprovalStages';



const propTypes = {
  userRoles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  lang: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  bonuses: PropTypes.array,
  getBonuses: PropTypes.func,
  setBonuses: PropTypes.func,
  createBonus: PropTypes.func,
  updateBonus: PropTypes.func,
  deleteBonus: PropTypes.func,
  changeBonusStatus: PropTypes.func,
  sendBonusesApprovedConfirmation: PropTypes.func,
  history: PropTypes.object.isRequired,
  approveTester: PropTypes.func.isRequired,
  updateTesterTrackingTime: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
};


class Bonuses extends React.Component {
  tabsMap = {
    artists: 'artists',
    designers: 'designers',
    testers: 'testers',
  };

  state = {
    task: '',
    bonus: '',
    reason: '',
    LOAD: false,
    currentUserId: null,
    filterName: '',
    deleteBonus: '',
    changeBonusStatus: '',
    targetDeleteBonus: '',
    showModalEditBonus: false,
    lastEditedTaskId: '',
    showListTasks: false,
    newBonus: '',
    isConfirmationDisabledByApprovedStage: false,
    tabs: [
      {
        title: this.props.lang['BONUSES.ARTISTS'],
        name: this.tabsMap.artists,
        beforeRender: () => {
          this.navigateToTab(this.tabsMap.artists);
        },
        body: () => {
          return (
            <>
              <BonusesList
                userRoles={this.props.userRoles}
                deleteBonus={this.deleteBonus}
                changeBonusStatus={this.changeBonusStatus}
                createBonus={this.createBonus}
                updateBonus={this.updateBonus}
                showListTasks={this.state.showListTasks}
                setModalShowListTasks={this._setModalShowListTasks}
                setUserId={this._setUserId}
                role={'coloring-artist'}
                type={'artist_drawing'}
                lang={this.props.lang}
                currentUserId={this.state.currentUserId}
                bonuses={this.getSortedBonuses('artist_drawing')}
                filterName={this.state.filterName}
              />
            </>
          );
        },
      },
      {
        title: this.props.lang['BONUSES.DESIGNERS'],
        name: this.tabsMap.designers,
        beforeRender: () => {
          this.navigateToTab(this.tabsMap.designers);
        },
        body: () => {
          return (
            <>
              <BonusesList
                userRoles={this.props.userRoles}
                deleteBonus={this.deleteBonus}
                changeBonusStatus={this.changeBonusStatus}
                createBonus={this.createBonus}
                updateBonus={this.updateBonus}
                showListTasks={this.state.showListTasks}
                setModalShowListTasks={this._setModalShowListTasks}
                setUserId={this._setUserId}
                role={'coloring-designer'}
                type={'designer_coloring'}
                lang={this.props.lang}
                currentUserId={this.state.currentUserId}
                bonuses={this.getSortedBonuses('designer_coloring')}
                filterName={this.state.filterName}
              />
            </>
          );
        },
      }, {
        title: this.props.lang['BONUSES.TESTERS'],
        name: this.tabsMap.testers,
        beforeRender: () => {
          this.navigateToTab(this.tabsMap.testers);
        },
        body: () => {
          return (
            <>
              <BonusesList
                userRoles={this.props.userRoles}
                deleteBonus={this.deleteBonus}
                changeBonusStatus={this.changeBonusStatus}
                createBonus={this.createBonus}
                updateBonus={this.updateBonus}
                showListTasks={this.state.showListTasks}
                setModalShowListTasks={this._setModalShowListTasks}
                setUserId={this._setUserId}
                role={'coloring-tester'}
                type={'device_testing'}
                lang={this.props.lang}
                lastEditedTaskId={this.state.lastEditedTaskId}
                currentUserId={this.state.currentUserId}
                bonuses={this.getSortedBonuses('device_testing')}
                filterName={this.state.filterName}
                approveTester={this.approveTester}
                updateTesterTrackingTime={this.updateTesterTrackingTime}
              />
            </>
          );
        },
      },
    ],
  };

  /**
   *
   * @param {string} type
   * @returns {Array}
   */
  getSortedBonuses = (type) => {
    const bonuses = this.props.bonuses;

    const isReadyToApprove = (bonus) => {
      const isNotApprovedBonusExists = bonus.tasks?.find((task) => {
        if (task.type === taskTypes.device_testing.type) {
          return task.bonus.status === TASK_BONUS_STATUS.WAITING || task.testing_time === 0;
        }

        return task.bonus.status === TASK_BONUS_STATUS.WAITING;
      });

      return !isNotApprovedBonusExists ? 1 : 0;
    };

    const isApproved = (bonus) => {
      const isApproved = bonus.tasks?.find((task) => {
        return task.price === 0;
      });

      return isApproved === undefined ? 1 : 0;
    };

    const calculatePrice = (bonus) => {
      return bonus?.tasks?.reduce((acc, task) => {
        return acc + Number(task.type === type ? task.price : 0);
      }, 0);
    };

    return bonuses.sort((bonusOne, bonusTwo) => {
      return isReadyToApprove(bonusOne) + isReadyToApprove(bonusOne) - isApproved(bonusTwo) - isReadyToApprove(bonusTwo);
    }).sort((bonusOne, bonusTwo) => {
      if (!isReadyToApprove(bonusOne) || !isReadyToApprove(bonusTwo)) {
        return 0;
      }

      return calculatePrice(bonusTwo) - calculatePrice(bonusOne);
    });
  };

  isConfirmationDisabled = () => {
    if (this.state.isConfirmationDisabledByApprovedStage) {
      return true;
    }
    const isPendingBonusesExist = this.calcTasksWithBonus(this.props.bonuses, TASK_BONUS_STATUS.WAITING) > 0;

    if (checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key ])) {
      const date = new Date();

      if (date.getDate() > lastAllowedDateToAcceptTesterBonus) {
        return true;
      }

      const isNotApproved = this.props.bonuses?.find((bonus) => {
        return bonus.tasks?.find((task) => {
          return task.price === 0;
        });
      });

      return isPendingBonusesExist || !!isNotApproved;
    }
    if (checkRole(this.props.userRoles, [ roles['lead-coloring-editor'].key ])) {
      return isPendingBonusesExist;
    }

    return true;
  };

  isConfirmationDisabledByApprovedStage = () => {
    getBonusesApprovedStagesRequest()
      .then((response) => {
        if (response?.data) {
          if (isFabros) {
            if (
              checkRole(this.props.userRoles, [ roles['lead-coloring-editor'].key ])
          && response?.data?.includes(bonusesApprovalStages.FABROS_COLORING_LEAD_EDITOR_APPROVED_BONUSES)
            ) {
              this.setState({
                isConfirmationDisabledByApprovedStage: true,
              });
            }
            if (checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key ])
          && response.data?.includes(bonusesApprovalStages.FABROS_COLORING_LEAD_TESTER_APPROVED_BONUSES)) {
              this.setState({
                isConfirmationDisabledByApprovedStage: true,
              });
            }
          } else if (
            checkRole(this.props.userRoles, [ roles['lead-coloring-editor'].key ])
        && response?.data?.includes(bonusesApprovalStages.XFLOW_COLORING_LEAD_EDITOR_APPROVED_BONUSES)
          ) {
            this.setState({
              isConfirmationDisabledByApprovedStage: true,
            });
          }
        }
      });
  };

  sendBonusesApprovedConfirmation = async () => {
    this.setState({
      LOAD: true,
    });
    await this.props.sendBonusesApprovedConfirmation()
      .then(() => {
        this.setState({
          LOAD: false,
        });
        this.isConfirmationDisabledByApprovedStage();
        this.props.notification('NOTIFICATION.SUCCESS_PAYMENTS_AGREED', 'success');
      })
      .catch(() => {
        this.setState({
          LOAD: false,
        });
        this.props.notification('NOTIFICATION.ERROR_PAYMENTS_AGREED', 'error');
      });
  };

  componentDidMount () {
    const initTabs = this.filterTabs();

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ tabs: initTabs });
    this.setTabs();
    if (this.props.users.length > 0) {
      this.getBonuses();
    }
    this.isConfirmationDisabledByApprovedStage();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.users.length > 0 && nextProps.users.length !== this.props.users.length) {
      this.getBonuses();
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setTabs();
    }
  }

  /** Calculate bonuses with required status
   *
   * @param {Array} arrayData
   * @param {string} status
   * @returns {number}
   */
  calcTasksWithBonus = (arrayData, status) => {
    let amount = 0;

    arrayData.forEach((item) => {
      item?.tasks?.forEach((task) => {
        if (task?.bonus?.status === status) {
          amount++;
        }
      });
    });

    return amount;
  };

  getBonuses = async () => {
    this.setState({
      LOAD: true,
    });

    let type = checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key ]) ? 'tester' : '';

    if (checkRole(this.props.userRoles, [ roles.administrator.key ])) {
      type = 'admin';
    }

    const bonuses = await this.props.getBonuses(type);
    const groupUsers = this.props.users.filter((item) => {
      return (_.intersection(item.groups, this.props.user.groups)).length;
    });

    if (checkRole(
      this.props.userRoles,
      [ roles['coloring-editor'].key ],
      [ roles['lead-coloring-editor'].key ]
    )) {
      bonuses.users = bonuses?.users.filter((item) => {
        return !!groupUsers.find((user) => user.id === item.user.id);
      });
    }

    const tasks = bonuses.users.length ? await getBatchTasksBonuses(bonuses.users) : [];
    const response = await buildBonuses(bonuses, tasks);

    this.props.setBonuses(response);
    this.setState({
      showModalEditBonus: false,
      targetEditBonus: '',
      newBonus: '',
      LOAD: false,
    });
  };

  /**
   * Approve Tester
   *
   * @param {int} testerId
   * @returns {Promise<void>}
   */
  approveTester = async (testerId) => {
    this.setState({
      LOAD: true,
    });

    await this.props.approveTester(testerId);
    await this.getBonuses();

    this.setState({
      LOAD: false,
    });
  };

  /**
   * Update Tester tracking time
   *
   * @param {int} taskId
   * @param {int} time
   * @returns {Promise<void>}
   */
  updateTesterTrackingTime = async (taskId, time) => {
    this.setState({
      LOAD: true,
      lastEditedTaskId: taskId,
    });

    await this.props.updateTesterTrackingTime(taskId, time);

    this.setState({
      LOAD: false,
    });
  };

  /**
   * Create bonus
   *
   * @param {int|null} amount
   * @param {int|null} taskId
   * @param {string|null} reason
   * @param {any} bonusStatus
   * @returns {Promise<void>}
   */
  createBonus = async (amount = null, taskId = null, reason = null, bonusStatus) => {
    this.setState({
      LOAD: true,
      lastEditedTaskId: taskId,
    });

    await this.props.createBonus({
      amount: amount || this.state.bonus,
      reason: reason || this.state.reason,
      task_id: taskId || this.state.task,
      bonusStatus,
    });

    this.setState({
      LOAD: false,
    });
  };

  /**
   * Update bonus
   *
   * @param {object} bonus
   * @returns {Promise<void>}
   */
  updateBonus = async (bonus) => {
    this.setState({
      LOAD: true,
      lastEditedTaskId: bonus.taskId,
    });

    await this.props.updateBonus({
      amount: bonus.amount || this.state.bonus,
      reason: bonus.reason || this.state.reason,
      task_id: bonus.taskId || this.state.taskId,
      bonusStatus: bonus.bonusStatus,
      bonusId: bonus.bonusId,
    });

    this.setState({
      LOAD: false,
    });
  };

  /**
   * Delete bonus
   *
   * @param {int} id
   * @returns {Promise<void>}
   */
  deleteBonus = async (id) => {
    this.setState({
      LOAD: true,
    });

    await this.props.deleteBonus(id);

    this.setState({
      LOAD: false,
      deleteBonus: '',
      targetDeleteBonus: '',
    });
  };

  /**
   * Change bonus status
   *
   * @param {int} taskId
   * @param {int} bonusId
   * @param {any} bonusStatus
   * @returns {Promise<void>}
   */
  changeBonusStatus = async (taskId, bonusId, bonusStatus) => {
    this.setState({
      LOAD: true,
      lastEditedTaskId: taskId,
    });

    await this.props.changeBonusStatus({
      task_id: taskId,
      bonus_id: bonusId,
      status: bonusStatus,
    });

    this.setState({
      LOAD: false,
    });
  };

  filterTabs () {
    return this.state.tabs.filter((tab) => {
      if (checkRole(this.props.userRoles, [ roles.administrator.key ])) {
        return true;
      }

      if (checkRole(this.props.userRoles, [ roles['lead-coloring-tester'].key ]) && tab.name === this.tabsMap.testers) {
        return true;
      }

      if (
        checkRole(
          this.props.userRoles,
          [ roles['lead-coloring-editor'].key, roles['coloring-editor'].key ]
        ) && tab.name !== this.tabsMap.testers
      ) {
        return true;
      }

      return false;
    });
  }

  setTabs () {
    const match = this.props.location.pathname.match(/\/bonuses\/(.*)/);
    const activeTab = match ? match[1] : '';

    if (!activeTab) {
      return;
    }

    const newTabs = this.filterTabs();

    newTabs.map(
      (tab) => {
        tab.active = tab.name === activeTab;
        return tab.active;
      },
    );

    this.setState({ tabs: newTabs });
  }

  /**
   * Navigate to tab
   *
   * @param {string} tabName
   * @returns {void}
   */
  navigateToTab (tabName) {
    const match = this.props.location.pathname.match(/\/bonuses\/(.*)/);
    const activeTab = match ? match[1] : '';

    if (!activeTab) {
      this.props.history.replace(urlPageBonusesSubmenu({ userType: this.tabsMap.artists }));
    }

    this.props.history.replace(tabName);
  }

  /**
   * Trigger modal
   *
   * @param {boolean} value
   * @returns {void}
   * @private
   */
  _setModalShowListTasks = (value) => {
    this.setState({
      showListTasks: value,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} value
   * @returns {void}
   * @private
   */
  _setUserId = (value) => {
    this.setState({
      currentUserId: value,
    });
  };

  render () {
    const { lang } = this.props;

    return (
      <>
        {this.state.LOAD ? (
          <Preload />
        ) : (
          <>
            <SubHeader
              title={lang['MENU.PAYMENTS']}
              main={
                <span>
                  <Form.Control
                    size='sm'
                    type='text'
                    placeholder={lang['GLOBAL.SEARCH']}
                    onChange={(event) => {
                      this.setState({
                        filterName: event.target.value,
                      });
                    }}
                  />
                </span>
              }
            />
            {checkRole(this.props.userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key, roles['lead-coloring-tester'].key ]) &&
              <BonusesStatsWidget
                stats={{
                  pending: this.calcTasksWithBonus(this.props.bonuses, TASK_BONUS_STATUS.WAITING),
                  approved: this.calcTasksWithBonus(this.props.bonuses, TASK_BONUS_STATUS.APPLIED),
                  canceled: this.calcTasksWithBonus(this.props.bonuses, TASK_BONUS_STATUS.CANCELED),
                }}
                confirmationDisabled={this.isConfirmationDisabled()}
                confirm={this.sendBonusesApprovedConfirmation}
                userRoles={this.props.userRoles}
              />
            }
            <div className="kt-portlet">
              <Tabs tabs={[ ...this.state.tabs ]} />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    bonuses: store.bonuses,
    user: store.user.user,
    users: store.users,
    userRoles: store.user.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBonuses: bindActionCreators(getBonuses, dispatch),
    setBonuses: bindActionCreators(setBonuses, dispatch),
    createBonus: bindActionCreators(createBonus, dispatch),
    changeBonusStatus: bindActionCreators(changeBonusStatus, dispatch),
    updateBonus: bindActionCreators(updateBonus, dispatch),
    sendBonusesApprovedConfirmation: bindActionCreators(sendBonusesApprovedConfirmation, dispatch),
    approveTester: bindActionCreators(approveTester, dispatch),
    deleteBonus: bindActionCreators(deleteBonus, dispatch),
    updateTesterTrackingTime: bindActionCreators(updateTesterTrackingTime, dispatch),
    notification: bindActionCreators(notification, dispatch),
  };
};

Bonuses.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(Bonuses);
