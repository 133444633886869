import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from '../../../../components/Loader';
import urlPageProjectDetails from '../../../../urls/urlPageProjectDetails';
import TagsMatch from './TagsMatch';
import ImageWithFallback from './ImageWithFallback';



const SimilarPictures = ({ isLoading, images }) => {
  const { lang } = useSelector(({
    language,
  }) => ({
    lang: language.lang,
  }), shallowEqual);

  const settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 4,
    rows: 2,
    arrows: true,
    lazyLoad: 'onDemand',
    swipe: true,
    infinite: false,
  };

  const getImage = (img, index) => (
    <div className="d-flex flex-column justify-content-center position-relative">
      <TagsMatch
        primaryDiff={img?.primary_diff}
        typeDiff={img?.type_diff}
        additionalDiff={img?.additional_diff}
      />
      <ImageWithFallback
        src={img.url}
        alt={`img-${index + 1}`}
      />
    </div>
  );

  return (
    <div>
      {!!images?.length &&
        <p className='font-weight-bold text-dark mb-1'>{lang['TAG_SYSTEM.TEMPLATES.SIMILAR_PICTURES']}:</p>}
      <div className="slider-container position-relative">
        {isLoading && <Loader centered showImg={false} />}
        <Slider {...settings}>
          {images?.map((img, index) => (
            <div key={uuidv4()}>
              {img?.project_id ? (
                <a target="_blank" href={urlPageProjectDetails({ projectId: img.project_id })} rel="noreferrer">{getImage(img, index)}</a>
              ) : getImage(img, index)}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

SimilarPictures.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
};

export default SimilarPictures;
