import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Preload from '../../widgets/Preload';
import freelancerActions from '../../store/freelancers/actions';
import getCountryName from '../../utils/getCountryName';
import { formatDate, formatStringToDate } from '../../utils/formats';
import getResidenceOptionsList from '../../utils/getResidenceOptionsList';
import { isBY, isBYCitizenship, isBYResidence, isRU, isRUResidence, isSwiftRU, isTransitIban } from '../../utils/checker';
import { isFabros, isXFLOW } from '../../utils/isFabros';
import { RUSSIA_INTERNATION_COUNTY_CODE } from '../../../const/system';
import PersonalInformationTab from './components/PersonalInformationTab';
import DocumentInformationTab from './components/DocumentInformationTab';
import FinanceInformationTab from './components/FinanceInformationTab';
import { employeeValidationSchema, freelancerValidationSchema } from './components/profileFormData';



const PersonalInformation = () => {
  const dispatch = useDispatch();
  const { loading, lang, user, countries } = useSelector(({ language, user, countries, freelancers }) => ({
    loading: freelancers.loading,
    lang: language.lang,
    user: user.user,
    countries: countries.countries,
  }), shallowEqual);
  const [ isDisabled, setIsDisabled ] = useState(true);
  const { control, watch, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(user?.is_employee ? employeeValidationSchema : freelancerValidationSchema),
    mode: 'onChange',
  });
  const formValues = watch();

  const getSelectedCountry = (countryCode, getCountryCode = false) => {
    const field = getCountryCode ? 'country_code' : 'code';
    // eslint-disable-next-line eqeqeq
    const country = countries.find((country) => country[field] == countryCode);

    if (country) {
      return {
        value: country[field],
        label: getCountryName(country),
      };
    }

    return '';
  };

  const onSubmit = (data) => {
    const freelancerData = {
      ...data,
      birthday: formatDate(data.birthday, 'yyyy-MM-dd'),
      transit_iban: isTransitIban(data) ? data.transit_iban : null,
      expiration_date: formatDate(data.expiration_date, 'yyyy-MM-dd'),
      country: data.country?.value,
      bic_number: isSwiftRU(data) ? data.bic_number : null,
    };

    if (!user.is_employee) {
      if (isFabros) {
        freelancerData.country_of_birth = data.country_of_birth?.value;
        freelancerData.place_of_birth = data.place_of_birth;
        freelancerData.citizenship = data.citizenship?.value;
        freelancerData.residence = data.residence === null ? null : data.residence.value;
        freelancerData.has_residence_confirmation = isBYCitizenship(data) ? Boolean(data.has_residence_confirmation?.value) : false;
        freelancerData.has_been_on_territory = data.has_residence_confirmation?.value ? !!data.has_been_on_territory?.value : false;
        freelancerData.personal_number = (!isRU(data) || isBYResidence(data)) && !isRUResidence(data) ? data.personal_number : null;
        freelancerData.date_of_issue = formatDate(data.date_of_issue, 'yyyy-MM-dd');
        freelancerData.insurance_number = isBY(data) ? data.insurance_number : null;
        freelancerData.inn = isRU(data) ? data.inn : null;
      }

      if (isXFLOW) {
        freelancerData.country_issuing_document = data.country_issuing_document?.value;
        freelancerData.bank_country = data.bank_country?.value;
        freelancerData.tin_country = data.tin_country?.value;
      }
    }

    dispatch(freelancerActions.updateFreelancer(freelancerData));
    setIsDisabled(true);
  };

  const cancelForm = () => {
    reset();
    setIsDisabled(true);
  };

  const editProfile = () => {
    setIsDisabled(false);
  };

  useEffect(() => {
    if (user.id && countries?.length) {
      const userData = {
        username: user.username,
        telegram: user?.personal_info?.telegram,
        firstname: user.firstname,
        lastname: user.lastname,
        patronymic: user.personal_info.patronymic,
        birthday: formatStringToDate(user.personal_info?.birthday),
        email: user.email,
        phone: user.personal_info?.phone,
        passport_type: user.document?.citizenship === RUSSIA_INTERNATION_COUNTY_CODE ? {
          label: user.document.passport_number?.length === 10 ? lang['REGISTRATION.ORDINARY_PASSPORT'] : lang['REGISTRATION.FOREIGN_PASSPORT'],
          value: user.document.passport_number?.length === 10 ? 'ordinary' : 'foreign',
        } : null,
        passport_number: user.document.passport_number,
        expiration_date: formatStringToDate(user.document.expiration_date),
        country: getSelectedCountry(user.finance_info.country),
        city: user.finance_info.city,
        address: user.finance_info.address,
        bic_number: user.finance_info.bic_number,
        iban: user.finance_info.iban,
        swift: user.finance_info.swift,
        transit_iban: user.finance_info.transit_iban,
      };

      if (isFabros) {
        userData.country_of_birth = getSelectedCountry(user.personal_info?.country_of_birth);
        userData.place_of_birth = user.personal_info?.place_of_birth;
        userData.citizenship = getSelectedCountry(user.document?.citizenship);
        userData.residence = getSelectedCountry(user?.document?.residence);
        userData.has_residence_confirmation = getResidenceOptionsList(lang).find((item) => {
          // eslint-disable-next-line eqeqeq
          return item.value == user.has_residence_confirmation;
        });
        userData.has_been_on_territory = getResidenceOptionsList(lang).find((item) => {
          // eslint-disable-next-line eqeqeq
          return item.value == user.has_been_on_territory;
        });
        userData.personal_number = user.document.personal_number;
        userData.date_of_issue = formatStringToDate(user.document.date_of_issue);
        userData.organization = user.document.organization;
        userData.insurance_number = user.personal_info.insurance_number;
        userData.inn = user.finance_info.inn ?? '';
      }

      if (isXFLOW) {
        userData.country_issuing_document = getSelectedCountry(user.document?.country_issuing_document);
        userData.bank = user.finance_info.bank;
        userData.bank_country = getSelectedCountry(user.finance_info.bank_country);
        userData.bank_account_name = user.finance_info.bank_account_name;
        userData.tin_country = getSelectedCountry(user.finance_info.tin_country, true);
        userData.tin_number = user.finance_info.tin_number;
        userData.post_code = user.finance_info.post_code;
      }

      reset(userData);
    }
  }, [ user, countries ]);

  return loading ? (
    <Preload />
  ) : (
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title font-weight-bold">
            {lang['CUSTOMER.PERSONAL_INFO']}
          </h3>
        </div>
      </div>
      <div className="kt-portlet__body d-block">
        <form onSubmit={handleSubmit(onSubmit)}>
          <PersonalInformationTab
            isDisabled={isDisabled}
            control={control}
            errors={errors}
            formValues={formValues}
          />
          {!user?.is_employee && (
            <DocumentInformationTab
              isDisabled={isDisabled}
              control={control}
              errors={errors}
              formValues={formValues}
            />
          )}
          {!user?.is_employee && (
            <FinanceInformationTab
              isDisabled={isDisabled}
              control={control}
              errors={errors}
              formValues={formValues}
            />
          )}
          {!isDisabled &&
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary btn-sm"
                type="button"
                onClick={cancelForm}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-success btn-sm kt-ml-20"
                type="submit"
              >
                {lang['GLOBAL.SAVE_CHANGES']}
              </button>
            </div>
          }
          {
            isDisabled &&
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-success btn-sm"
                type="button"
                onClick={editProfile}
              >
                {lang['GLOBAL.EDIT']}
              </button>
            </div>
          }
        </form>
      </div>
    </div>
  );
};

export default PersonalInformation;
